import React, { useCallback, useState } from 'react';
import { HexAlphaColorPicker } from 'react-colorful';
import { useModal } from '../components/modal';
import { useFleets, useOperatingModes } from '../fetchers/fleet';
import I18n from '../i18n';
import { OperatingMode, UserFleet } from '@solar-data/schemas/lib/owned-by/solar';
import { isGranted, IsGranted } from '../privateRoute';
import { ROLE } from '../constants/roles';

function EditOperatingModeDialog({
  operatingMode,
  close,
  fleets,
  currentFleet,
}: {
  fleets: UserFleet[];
  currentFleet: string | undefined;
  operatingMode?: OperatingMode;
  close: (u: Partial<OperatingMode> | undefined | 'delete') => void;
}) {
  const [name, setName] = useState(operatingMode?.name ?? '');
  const [color, setColor] = useState(operatingMode?.color ?? '');
  const [useForStatistics, setUseForStatistics] = useState(operatingMode?.useForStatistics ?? false);

  return (
    <div>
      <h2>{I18n.t('solar.fleet.operatingModes.edit')}</h2>
      <label>{I18n.t('solar.fleet.operatingModes.name')}</label>
      <input
        placeholder={I18n.t('solar.fleet.operatingModes.name')}
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <label>{I18n.t('solar.fleet.operatingModes.color')}</label>
      <HexAlphaColorPicker color={color} onChange={setColor} />
      <div>
        <input
          type="checkbox"
          checked={useForStatistics}
          onChange={(e) => setUseForStatistics(e.target.checked)}
          id="useForStatistics"
        />
        <label htmlFor="useForStatistics">{I18n.t('solar.fleet.operatingModes.useForStatistics')}</label>
      </div>
      <div className="buttons">
        <button disabled={!name || !color} onClick={() => close({ name, color, useForStatistics })}>
          {I18n.t('solar.ok')}
        </button>
        <button onClick={() => close(undefined)}>{I18n.t('solar.cancel')}</button>
        {operatingMode && isGranted([ROLE.CAN_DELETE_OPERATION_MODES], fleets, currentFleet) ? (
          <button disabled={operatingMode.isDefault} onClick={() => close('delete')}>
            {I18n.t('solar.fleet.deleteButton')}
          </button>
        ) : undefined}
      </div>
    </div>
  );
}

export default function OperatingModes() {
  const { operatingModes, createOperatingMode, updateOperatingMode, deleteOperatingMode } = useOperatingModes();
  const modal = useModal();
  const { fleets, currentFleet } = useFleets();

  const add = useCallback(async () => {
    const newOperatingMode = await modal<Partial<OperatingMode> | undefined | 'delete'>((close) => (
      <EditOperatingModeDialog close={close} fleets={fleets} currentFleet={currentFleet} />
    ));
    if (newOperatingMode && newOperatingMode !== 'delete') {
      await createOperatingMode(newOperatingMode);
    }
  }, [modal, createOperatingMode, currentFleet, fleets]);

  const edit = useCallback(
    async (operatingMode: OperatingMode) => {
      const result = await modal<Partial<OperatingMode> | undefined | 'delete'>((close) => (
        <EditOperatingModeDialog
          operatingMode={operatingMode}
          close={close}
          fleets={fleets}
          currentFleet={currentFleet}
        />
      ));
      if (result === 'delete') {
        await deleteOperatingMode(operatingMode.id);
      } else if (result !== undefined) {
        await updateOperatingMode(operatingMode.id, result);
      }
    },
    [modal, updateOperatingMode, deleteOperatingMode, currentFleet, fleets],
  );

  return (
    <IsGranted roles={[ROLE.CAN_VIEW_OPERATION_MODES]}>
      <div className="cards">
        <div className="card full-width">
          <h2>{I18n.t('solar.fleet.operatingModes.title')}</h2>
          <table>
            <thead>
              <tr>
                <th>{I18n.t('solar.fleet.operatingModes.name')}</th>
                <th>{I18n.t('solar.fleet.operatingModes.color')}</th>
                <th className="no-mobile">{I18n.t('solar.fleet.operatingModes.isDefault')}</th>
                <th className="no-mobile">{I18n.t('solar.fleet.operatingModes.useForStatistics')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {operatingModes.map((om) => (
                <tr key={om.id}>
                  <td>{om.name}</td>
                  <td>
                    <div className="operatingModeIcon" style={{ backgroundColor: om.color }} />
                  </td>
                  <td className="no-mobile">{I18n.t(om.isDefault ? 'solar.boolean.true' : 'solar.boolean.false')}</td>
                  <td className="no-mobile">
                    {I18n.t(om.useForStatistics ? 'solar.boolean.true' : 'solar.boolean.false')}
                  </td>
                  <td>
                    <IsGranted roles={[ROLE.CAN_EDIT_OPERATION_MODES]}>
                      <button className="small" onClick={() => edit(om)}>
                        {I18n.t('solar.fleet.editButton')}
                      </button>
                    </IsGranted>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>
            <IsGranted roles={[ROLE.CAN_ADD_OPERATION_MODES]}>
              <button onClick={add}>{I18n.t('solar.fleet.addButton')}</button>
            </IsGranted>
          </p>
        </div>
      </div>
    </IsGranted>
  );
}
