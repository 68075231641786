import React, { useContext, useState, useCallback } from 'react';
import { ErrorProps, Error as ErrorMsg } from '../components/networking';
import I18n from '../i18n';

interface Tokens {
  access_token: string;
  access_token_lifespan: number;
  refresh_token: string;
}

async function postLogin(email: string, code: string): Promise<Tokens | undefined> {
  const res = await fetch('/api/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'password',
      username: email,
      password: code,
      client_id: 'solar',
    }),
  });
  if (!res.ok) {
    if (res.status === 401) {
      return undefined;
    }
    throw await res.text();
  }
  return await res.json();
}

async function postRefresh(refreshToken: string): Promise<Tokens | undefined> {
  const res = await fetch('/api/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: 'solar',
    }),
  });
  if (!res.ok) {
    if (res.status === 401) {
      return undefined;
    }
    throw await res.text();
  }
  return await res.json();
}

async function postRequestLoginToken(email: string): Promise<void> {
  const res = await fetch('/api/auth/login_token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': I18n.currentLocale(),
    },
    body: JSON.stringify({
      connection: 'email',
      email,
      client_id: 'solar',
    }),
  });
  if (!res.ok) {
    throw new Error(await res.text());
  }
}

async function postRevokeRefreshToken(accessToken: string, refreshToken: string): Promise<void> {
  const res = await fetch('/api/auth/revoke', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': I18n.currentLocale(),
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      refresh_token: refreshToken,
    }),
  });
  if (!res.ok) {
    throw await res.text();
  }
}

interface AuthProps {
  isLoggedIn: boolean;
  isLoading: boolean;
  loginFailed: boolean;
  requestCode(email: string): Promise<void>;
  login(email: string, code: string): Promise<boolean>;
  logout(): void;
  getHeaders(): Promise<Record<string, string>>;
}

const AuthContext = React.createContext({} as AuthProps);

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const [isLoggedIn, setIsLoggedIn] = useState(() => !!window.localStorage.getItem('sonoAccessToken'));
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(undefined as ErrorProps | undefined);
  const [loginFailed, setLoginFailed] = useState(false);

  const requestCode = useCallback(async (email: string) => {
    try {
      setLoading(true);
      setLoginFailed(false);
      await postRequestLoginToken(email);
    } catch (e) {
      setError({
        scope: 'loginError',
        detail: `${e}`,
        timestamp: Date.now(),
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const login = useCallback(async (email: string, code: string) => {
    try {
      setLoading(true);
      const res = await postLogin(email, code);
      setError(undefined);
      if (res) {
        setLoginFailed(false);
        setIsLoggedIn(true);
        window.localStorage.setItem('sonoAccessToken', res.access_token);
        window.localStorage.setItem('sonoAccessTokenValidUntil', `${Date.now() + res.access_token_lifespan * 1000}`);
        window.localStorage.setItem('sonoRefreshToken', res.refresh_token);
        return true;
      }
      setLoginFailed(true);
      return false;
    } catch (e) {
      setError({
        scope: 'loginError',
        detail: `${e}`,
        timestamp: Date.now(),
      });
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(() => {
    const accessToken = window.localStorage.getItem('sonoAccessToken');
    const refreshToken = window.localStorage.getItem('sonoRefreshToken');
    if (accessToken && refreshToken) {
      // Don't await. If it fails, we still delete everything from the session
      postRevokeRefreshToken(accessToken, refreshToken).catch((e) => console.log(e));
    }

    window.localStorage.removeItem('sonoAccessToken');
    window.localStorage.removeItem('sonoAccessTokenValidUntil');
    window.localStorage.removeItem('sonoRefreshToken');
    setIsLoggedIn(false);
  }, []);

  const getHeaders = useCallback(async (): Promise<Record<string, string>> => {
    const accessTokenValidUntil = window.localStorage.getItem('sonoAccessTokenValidUntil');
    if (accessTokenValidUntil && Number.parseInt(accessTokenValidUntil) < Date.now() + 10_000) {
      // Expired access token in session storage -> refresh
      try {
        const res = await postRefresh(window.localStorage.getItem('sonoRefreshToken') ?? '');
        if (res) {
          window.localStorage.setItem('sonoAccessToken', res.access_token);
          window.localStorage.setItem('sonoAccessTokenValidUntil', `${Date.now() + res.access_token_lifespan * 1000}`);
          window.localStorage.setItem('sonoRefreshToken', res.refresh_token);
        }
      } catch (e) {
        console.warn(`Error refreshing token: ${e}`);
      }
    }

    return { Authorization: `Bearer ${window.localStorage.getItem('sonoAccessToken')}` };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        loginFailed,
        getHeaders,
        requestCode,
        login,
        logout,
      }}
    >
      {error && <ErrorMsg {...error} />}
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
