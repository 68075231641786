import React, { useCallback, useState } from 'react';
import { useModal } from '../components/modal';
import { useFleets, useUsers } from '../fetchers/fleet';
import I18n from '../i18n';
import { User, UserFleet } from '@solar-data/schemas/lib/owned-by/solar';
import { isGranted, IsGranted } from '../privateRoute';
import { ROLE } from '../constants/roles';

function EditUserDialog({
  user,
  close,
  fleets,
  currentFleet,
}: {
  fleets: UserFleet[];
  currentFleet: string | undefined;
  user?: User;
  close: (u: Partial<User> | undefined | 'delete') => void;
}) {
  const [email, setEmail] = useState(user?.email ?? '');
  const [role, setRole] = useState(user?.role ?? 'viewer');

  return (
    <div>
      <h2>{I18n.t('solar.fleet.users.edit')}</h2>
      <label>{I18n.t('solar.fleet.users.email')}</label>
      <input
        placeholder={I18n.t('solar.fleet.users.email')}
        value={email || ''}
        onChange={(e) => setEmail(e.target.value.trim())}
        required
        disabled={!!user}
      />
      <label>{I18n.t('solar.fleet.users.role')}</label>
      <select onChange={(e) => setRole(e.target.value as 'admin' | 'viewer')} value={role}>
        <option value={'viewer'}>{I18n.t('solar.fleet.users.role_viewer')}</option>
        <option value={'admin'}>{I18n.t('solar.fleet.users.role_admin')}</option>
      </select>
      <div className="buttons">
        <button onClick={() => close(user ? { role } : { email, role })}>{I18n.t('solar.ok')}</button>
        <button onClick={() => close(undefined)}>{I18n.t('solar.cancel')}</button>
        {user && isGranted([ROLE.CAN_DELETE_USERS], fleets, currentFleet) ? (
          <button onClick={() => close('delete')}>{I18n.t('solar.fleet.deleteButton')}</button>
        ) : undefined}
      </div>
    </div>
  );
}

export default function Users() {
  const { users, createUser, updateUser, deleteUser } = useUsers();
  const { fleets, currentFleet } = useFleets();
  const modal = useModal();

  const add = useCallback(async () => {
    const newUser = await modal<Partial<User> | undefined | 'delete'>((close) => (
      <EditUserDialog close={close} fleets={fleets} currentFleet={currentFleet} />
    ));
    if (newUser && newUser !== 'delete') {
      await createUser(newUser);
    }
  }, [modal, createUser, currentFleet, fleets]);

  const edit = useCallback(
    async (user: User) => {
      const result = await modal<Partial<User> | undefined | 'delete'>((close) => (
        <EditUserDialog user={user} close={close} fleets={fleets} currentFleet={currentFleet} />
      ));
      if (result === 'delete') {
        await deleteUser(user.accountId);
      } else if (result !== undefined) {
        await updateUser(user.accountId, result);
      }
    },
    [modal, updateUser, deleteUser, currentFleet, fleets],
  );

  return (
    <IsGranted roles={[ROLE.CAN_VIEW_USERS]}>
      <div className="cards">
        <div className="card full-width">
          <h2>{I18n.t('solar.fleet.users.title')}</h2>
          <table>
            <thead>
              <tr>
                <th>{I18n.t('solar.fleet.users.email')}</th>
                <th>{I18n.t('solar.fleet.users.role')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {users.map((u) => (
                <tr key={u.accountId}>
                  <td>{u.email}</td>
                  <td>{I18n.t(`solar.fleet.users.role_${u.role}`)}</td>
                  <IsGranted roles={[ROLE.CAN_ADD_USERS]}>
                    <td>
                      <button className="small" onClick={() => edit(u)}>
                        {I18n.t('solar.fleet.editButton')}
                      </button>
                    </td>
                  </IsGranted>
                </tr>
              ))}
            </tbody>
          </table>
          <IsGranted roles={[ROLE.CAN_ADD_USERS]}>
            <p>
              <button onClick={add}>{I18n.t('solar.fleet.addButton')}</button>
            </p>
          </IsGranted>
        </div>
      </div>
    </IsGranted>
  );
}
