import { useState, useEffect } from 'react';
import { useAuth } from './auth';
import { doFetch } from './common';
import { useFleets } from './fleet';
import { DocumentType, Document } from '@solar-data/schemas/lib/owned-by/solar';

interface DocumentsProps {
  isLoading: boolean;
  documents?: Document[];
  error?: string;
}

export function useDocuments(documentType: DocumentType): DocumentsProps {
  const [documents, setDocuments] = useState(undefined as Document[] | undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined as string | undefined);

  const { logout, getHeaders } = useAuth();
  const { currentFleet } = useFleets();

  useEffect(() => {
    (async () =>
      doFetch<Document[]>(
        `api/fleets/${currentFleet}/documents/${documentType}`,
        { headers: await getHeaders() },
        setIsLoading,
        setError,
        logout,
        setDocuments,
      ))();
  }, [getHeaders, logout, currentFleet, documentType]);

  return {
    isLoading,
    documents,
    error,
  };
}
