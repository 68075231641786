import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { ErrorProps, Error, Loading } from '../components/networking';
import { useAuth } from './auth';
import { UserFleet, Vehicle, User, OperatingMode } from '@solar-data/schemas/lib/owned-by/solar';

interface ContextInterface {
  fleets: {
    fleets: UserFleet[];
    currentFleet?: string;
    currentRole?: 'admin' | 'viewer';
    createFleet: (f: Partial<UserFleet>) => Promise<void>;
    updateFleet: (slug: string, f: Partial<UserFleet>) => Promise<void>;
    deleteFleet: (slug: string) => Promise<void>;
  };
  vehicles: {
    vehicles: Vehicle[];
    createVehicle: (v: Partial<Vehicle>) => Promise<void>;
    updateVehicle: (id: number, v: Partial<Vehicle>) => Promise<void>;
    deleteVehicle: (id: number) => Promise<void>;
  };
  users: {
    users: User[];
    createUser: (u: Partial<User>) => Promise<void>;
    updateUser: (accountId: string, u: Partial<User>) => Promise<void>;
    deleteUser: (accountId: string) => Promise<void>;
  };
  operatingModes: {
    operatingModes: OperatingMode[];
    createOperatingMode: (o: Partial<OperatingMode>) => Promise<void>;
    updateOperatingMode: (id: number, o: Partial<OperatingMode>) => Promise<void>;
    deleteOperatingMode: (id: number) => Promise<void>;
  };
}

const FleetsContext = createContext({} as ContextInterface);

export function FleetsProvider({ children, currentFleet }: React.PropsWithChildren<{}> & { currentFleet: string }) {
  const [fleets, setFleets] = useState(undefined as UserFleet[] | undefined);
  const [vehicles, setVehicles] = useState(undefined as { vehicles: Vehicle[]; fleet: string } | undefined);
  const [users, setUsers] = useState(undefined as { users: User[]; fleet: string } | undefined);
  const [operatingModes, setOperatingModes] = useState(
    undefined as { operatingModes: OperatingMode[]; fleet: string } | undefined,
  );

  const [error, setError] = useState(undefined as ErrorProps | undefined);

  const { logout, getHeaders } = useAuth();

  const read = useCallback(
    async function <T>(what: string, setter: (a: T[]) => void): Promise<void> {
      if (what !== 'fleets' && currentFleet === '*') {
        setter([]);
        return;
      }

      try {
        const resp = await fetch(what === 'fleets' ? `api/fleets` : `api/fleets/${currentFleet}/${what}`, {
          headers: await getHeaders(),
        });

        if (resp.ok) {
          setter(await resp.json());
        } else if (resp.status === 401) {
          logout();
          window.location.href = '#/login';
        } else {
          throw await resp.text();
        }
      } catch (e) {
        setError({ scope: 'errorLoadingData', detail: `${e}`, timestamp: Date.now(), reloadRequired: true });
      }
    },
    [currentFleet, getHeaders, logout],
  );

  const mutate = useCallback(
    async function <T>(
      method: string,
      what: string,
      id: string | undefined,
      body: undefined | Partial<T>,
      setter: (a: T[]) => void,
    ): Promise<void> {
      try {
        const resp = await fetch(
          what === 'fleets'
            ? id
              ? `api/fleets/${id}`
              : `api/fleets`
            : `api/fleets/${currentFleet}/${what}${id ? `/${id}` : ''}`,
          {
            headers: {
              ...(await getHeaders()),
              'Content-Type': 'application/json',
            },
            method,
            ...(body ? { body: JSON.stringify(body) } : {}),
          },
        );

        if (resp.ok) {
          await read(what, setter);
        } else if (resp.status === 401) {
          logout();
          window.location.href = '#/login';
        } else {
          throw await resp.text();
        }
      } catch (e) {
        setError({ scope: 'errorSavingData', detail: `${e}`, timestamp: Date.now(), reloadRequired: true });
      }
    },
    [read, getHeaders, currentFleet, logout],
  );

  useEffect(() => {
    (async () => {
      setError(undefined);
      let newFleets: UserFleet[] = [];

      await read<UserFleet>('fleets', (fleets) => (newFleets = fleets));
      // newFleets = newFleets.filter((f) => f.slug !== 'admin' || f.role === 'admin');
      setFleets(newFleets);
      await read<Vehicle>('vehicles', (v) => setVehicles({ vehicles: v, fleet: currentFleet }));
      await read<OperatingMode>('operatingModes', (om) =>
        setOperatingModes({ operatingModes: om, fleet: currentFleet }),
      );
      if (newFleets?.find((f) => f.slug === currentFleet)?.role === 'admin') {
        await read<User>('users', (u) => setUsers({ users: u, fleet: currentFleet }));
      } else {
        setUsers({ users: [], fleet: currentFleet });
      }
    })();
  }, [currentFleet, read]);

  useEffect(() => {
    if (currentFleet === '*' && fleets && fleets.length > 0) {
      window.location.hash = `/${fleets[0].slug}`;
    }
  }, [currentFleet, fleets]);

  const createFleet = async (f: Partial<UserFleet>) => await mutate('POST', 'fleets', undefined, f, setFleets);
  const updateFleet = async (slug: string, f: Partial<UserFleet>) => await mutate('PUT', `fleets`, slug, f, setFleets);
  const deleteFleet = async (slug: string) => await mutate('DELETE', `fleets`, slug, undefined, setFleets);

  const createVehicle = async (f: Partial<Vehicle>) =>
    await mutate('POST', 'vehicles', undefined, f, (v) => setVehicles({ vehicles: v, fleet: currentFleet }));
  const updateVehicle = async (id: number, f: Partial<Vehicle>) =>
    await mutate('PUT', `vehicles`, `${id}`, f, (v) => setVehicles({ vehicles: v, fleet: currentFleet }));
  const deleteVehicle = async (id: number) =>
    await mutate<Vehicle>('DELETE', `vehicles`, `${id}`, undefined, (v) =>
      setVehicles({ vehicles: v, fleet: currentFleet }),
    );

  const createUser = async (f: Partial<User>) =>
    await mutate('POST', 'users', undefined, f, (u) => setUsers({ users: u, fleet: currentFleet }));
  const updateUser = async (accountId: string, f: Partial<User>) =>
    await mutate('PUT', `users`, accountId, f, (u) => setUsers({ users: u, fleet: currentFleet }));
  const deleteUser = async (accountId: string) =>
    await mutate<User>('DELETE', `users`, accountId, undefined, (u) => setUsers({ users: u, fleet: currentFleet }));

  const createOperatingMode = async (om: Partial<OperatingMode>) =>
    await mutate('POST', 'operatingModes', undefined, om, (om) =>
      setOperatingModes({ operatingModes: om, fleet: currentFleet }),
    );
  const updateOperatingMode = async (id: number, om: Partial<OperatingMode>) =>
    await mutate('PUT', 'operatingModes', `${id}`, om, (om) =>
      setOperatingModes({ operatingModes: om, fleet: currentFleet }),
    );
  const deleteOperatingMode = async (id: number) =>
    await mutate<OperatingMode>('DELETE', `operatingModes`, `${id}`, undefined, (om) =>
      setOperatingModes({ operatingModes: om, fleet: currentFleet }),
    );

  return (
    <FleetsContext.Provider
      value={{
        fleets: {
          fleets: fleets ?? [],
          createFleet,
          updateFleet,
          deleteFleet,
          currentFleet,
          currentRole: fleets?.find((f) => f.slug === currentFleet)?.role,
        },
        vehicles: {
          vehicles: vehicles && vehicles.fleet === currentFleet ? vehicles.vehicles : [],
          createVehicle,
          updateVehicle,
          deleteVehicle,
        },
        users: {
          users: users && users.fleet === currentFleet ? users.users : [],
          createUser,
          updateUser,
          deleteUser,
        },
        operatingModes: {
          operatingModes: operatingModes && operatingModes.fleet === currentFleet ? operatingModes.operatingModes : [],
          createOperatingMode,
          updateOperatingMode,
          deleteOperatingMode,
        },
      }}
    >
      {error ? <Error {...error} /> : fleets && vehicles && users ? children : <Loading />}
    </FleetsContext.Provider>
  );
}

export const useFleets = () => useContext(FleetsContext)!.fleets;
export const useVehicles = () => useContext(FleetsContext)!.vehicles;
export const useUsers = () => useContext(FleetsContext)!.users;
export const useOperatingModes = () => useContext(FleetsContext)!.operatingModes;
