import React, { createContext, useCallback, useContext, useState } from 'react';
import { Moment } from 'moment';

interface SelectedDayProps {
  selectedDay: string | undefined;
  selectedDays: string[];
  setSelectedDay(day: string | undefined): void;
  setDaySelected(day: string, selected: boolean): void;
  setSelectedDays(days: string[]): void;
}

const SelectedDayContext = createContext({} as SelectedDayProps);

export function SelectedDayProvider({ children }: React.PropsWithChildren<{}>) {
  const [selectedDays, setSelectedDays] = useState([] as string[]);

  const setSelectedDay = useCallback((day: string | undefined) => setSelectedDays(day ? [day] : []), []);
  const setDaySelected = useCallback(
    (day: string, selected: boolean) =>
      setSelectedDays((sds) => [...(selected ? [day] : []), ...sds.filter((sd) => sd !== day)]),
    [],
  );

  return (
    <SelectedDayContext.Provider
      value={{ selectedDay: selectedDays[0], selectedDays, setSelectedDay, setDaySelected, setSelectedDays }}
    >
      {children}
    </SelectedDayContext.Provider>
  );
}

export function useSelectedDay() {
  return useContext(SelectedDayContext);
}
