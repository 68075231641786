import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import { FleetsProvider, useFleets } from './fetchers/fleet';
import I18n from './i18n';
import './loggedIn.scss';
import { Fleet, UserFleet } from '@solar-data/schemas/lib/owned-by/solar';
import { useAuth } from './fetchers/auth';
import { hirachie, ROLE } from './constants/roles';
import { AccessDenied } from '.';

function SelectFleet({ fleets }: { fleets: Fleet[] }) {
  const [open, setOpen] = useState(false);

  return (
    <span className="selector">
      <span className="arrow" onClick={() => setOpen(!open)}>
        ▼
      </span>
      {open ? (
        <ul className="dropdown dropdown-menu">
          {fleets.map((fleet) => (
            <li key={fleet.slug} onClick={() => setOpen(false)}>
              <a href={`#/${fleet.slug}`}>{fleet.name}</a>
            </li>
          ))}
        </ul>
      ) : null}
    </span>
  );
}

function Common({ children }: React.PropsWithChildren<{}>) {
  const { fleets, currentFleet } = useFleets();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setShowMenu(!!fleets && !!currentFleet);
  }, [fleets, currentFleet]);

  return (
    <div className="logged-in">
      {showMenu && menuOpen ? (
        <div className={menuOpen ? 'menu' : ''}>
          <h1>
            <span className="menu-button" onClick={() => setMenuOpen(false)}>
              ☰
            </span>{' '}
            {fleets.find((f) => f.slug === currentFleet)?.name}
          </h1>
          <ul>
            <IsGranted roles={[ROLE.CAN_VIEW_DASHBOARD]}>
              <li>
                <a href={`#/${currentFleet}`} onClick={() => setMenuOpen(false)}>
                  {I18n.t('solar.dashboard.title')}
                </a>
              </li>
            </IsGranted>
            <IsGranted roles={[ROLE.CAN_VIEW_DASHBOARD]}>
              <li>
                <a href={`#/${currentFleet}/reports`} onClick={() => setMenuOpen(false)}>
                  {I18n.t('solar.documents.reports.title')}
                </a>
              </li>
            </IsGranted>
            <IsGranted roles={[ROLE.CAN_VIEW_DASHBOARD]}>
              <li>
                <a href={`#/${currentFleet}/docs`} onClick={() => setMenuOpen(false)}>
                  {I18n.t('solar.documents.docs.title')}
                </a>
              </li>
            </IsGranted>
            <IsGranted roles={[ROLE.CAN_VIEW_FAQS]}>
              <li>
                <a href={`#/${currentFleet}/faqs`} onClick={() => setMenuOpen(false)}>
                  {I18n.t('solar.faqs.title')}
                </a>
              </li>
            </IsGranted>
            <IsGranted roles={[ROLE.CAN_VIEW_VEHICLES]}>
              <li>
                <a href={`#/${currentFleet}/vehicles`} onClick={() => setMenuOpen(false)}>
                  {I18n.t('solar.fleet.vehicles.title')}
                </a>
              </li>
            </IsGranted>
            <IsGranted roles={[ROLE.CAN_VIEW_OPERATION_MODES]}>
              <li>
                <a href={`#/${currentFleet}/operatingModes`} onClick={() => setMenuOpen(false)}>
                  {I18n.t('solar.fleet.operatingModes.title')}
                </a>
              </li>
            </IsGranted>
            <IsGranted roles={[ROLE.CAN_VIEW_USERS]}>
              <li>
                <a href={`#/${currentFleet}/users`} onClick={() => setMenuOpen(false)}>
                  {I18n.t('solar.fleet.users.title')}
                </a>
              </li>
            </IsGranted>
            <IsGranted roles={[ROLE.CAN_VIEW_FLEETS]}>
              <li>
                <a href={`#/admin/fleets`} onClick={() => setMenuOpen(false)}>
                  {I18n.t('solar.fleet.fleets.title')}
                </a>
              </li>
            </IsGranted>
          </ul>
        </div>
      ) : null}
      <h1>
        {showMenu ? (
          <span className="menu-button" onClick={() => setMenuOpen(true)}>
            ☰
          </span>
        ) : null}{' '}
        {fleets.find((f) => f.slug === currentFleet)?.name}
        {fleets.length > 1 && !menuOpen ? <SelectFleet fleets={fleets} /> : null}
      </h1>
      {fleets && fleets.length > 0 ? (
        children
      ) : (
        <div className="cards">
          <div className="card">
            <h2>{I18n.t('solar.noFleets.title')}</h2>
            <p>{I18n.t('solar.noFleets.text')}</p>
          </div>
        </div>
      )}
    </div>
  );
}

function allRolesMatch(requiredRoles: ROLE[], allRoles: ROLE[]): boolean {
  let countMatches = 0;
  let countMissMatches = 0;
  requiredRoles.forEach((r) => {
    if (allRoles.includes(r)) {
      countMatches++;
    } else {
      countMissMatches++;
    }
  });

  return (
    requiredRoles.length > 0 &&
    countMatches + countMissMatches === requiredRoles.length &&
    countMatches === requiredRoles.length
  );
}

export const isGranted = (roles: ROLE[], fleets: UserFleet[], currentFleetSlug: string | undefined): boolean => {
  const adminFleet = fleets.find((f) => f.slug === 'admin');
  const currentFleet = fleets.find((f) => f.slug === currentFleetSlug);
  const currentFleetRole = currentFleet?.role;
  const adminFleetRole = adminFleet?.role;
  const isInAdminFleet = Boolean(adminFleet && (adminFleetRole === 'admin' || adminFleetRole === 'viewer'));
  const isInCurrentFleet = Boolean(currentFleet && (currentFleetRole === 'admin' || currentFleetRole === 'viewer'));

  const isSupperAdmin = isInAdminFleet && adminFleetRole === 'admin';
  const isGlobalAdmin = isInAdminFleet && adminFleetRole === 'viewer';
  const isFleetAdmin = isInCurrentFleet && currentFleetRole === 'admin';
  const isFleetViewer = isInCurrentFleet && currentFleetRole === 'viewer';
  const isGuest = !isFleetViewer && !isFleetAdmin && !isSupperAdmin && !isGlobalAdmin;

  if (isSupperAdmin) {
    return allRolesMatch(roles, hirachie[ROLE.SUPER_ADMIN]);
  }
  if (isGlobalAdmin) {
    return allRolesMatch(roles, hirachie[ROLE.GLOBAL_ADMIN]);
  }
  if (isFleetAdmin) {
    return allRolesMatch(roles, hirachie[ROLE.FLEET_ADMIN]);
  }
  if (isFleetViewer) {
    return allRolesMatch(roles, hirachie[ROLE.VIEWER]);
  }
  if (isGuest) {
    return allRolesMatch(roles, hirachie[ROLE.GUEST]);
  }

  return false;
};

export function IsGranted({
  children,
  roles,
  onDecline,
}: {
  children: JSX.Element;
  roles: ROLE[];
  onDecline?: JSX.Element;
}): JSX.Element {
  const { isLoggedIn, isLoading } = useAuth();
  const fleets = useFleets()?.fleets,
    currentFleet = useFleets()?.currentFleet;
  if (!fleets || !currentFleet)
    if (!isLoggedIn || isLoading || fleets === undefined || !currentFleet === undefined) {
      return <></>;
    }

  return isGranted(roles, fleets, currentFleet) ? children : onDecline ? onDecline : <></>;
}

export function PrivateRoute({ children, roles }: { children: JSX.Element; roles: ROLE[] }) {
  const { fleet } = useParams();
  let location = useLocation();
  const { isLoggedIn, isLoading } = useAuth();
  if (isLoading) {
    return <p className="container">Checking auth..</p>;
  }
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return (
    <FleetsProvider currentFleet={fleet || '*'}>
      <IsGranted roles={roles} onDecline={<AccessDenied />}>
        <Common key={fleet}>{children}</Common>
      </IsGranted>
    </FleetsProvider>
  );
}
