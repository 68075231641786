import I18n from 'i18n-js';
import React, { useState } from 'react';
import './networking.scss';
import './modal.scss';

export function Loading() {
  return (
    <div className="modal background">
      <div className="spinner" />
    </div>
  );
}

export interface ErrorProps {
  scope: string;
  detail: string;
  timestamp: number;
  reloadRequired?: boolean;
}

export function Error({ scope, detail, timestamp, reloadRequired }: ErrorProps) {
  const [confirmed, setConfirmed] = useState(undefined as number | undefined);

  return confirmed !== timestamp ? (
    <>
      <div className="modal background" />
      <div className="modal dialog centered networking error">
        <h2>{I18n.t(`solar.errors.${scope}.title`)}</h2>
        <p>{I18n.t(`solar.errors.${scope}.text`)}</p>
        <div>
          <label htmlFor="showDetails">{I18n.t('solar.errors.showDetails')}</label>
        </div>
        <input type="checkbox" id="showDetails" />
        <div className="detail">{detail}</div>
        <button
          onClick={() => {
            setConfirmed(timestamp);
            if (reloadRequired) window.location.reload();
          }}
        >
          {I18n.t('solar.errors.confirm')}
        </button>
      </div>
    </>
  ) : (
    <></>
  );
}
