import { Vehicle } from '@solar-data/schemas/lib/owned-by/solar';
import { useState, useCallback, useRef, useEffect } from 'react';

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoic29ub21vdG9yc2dtYmgiLCJhIjoiY2tpNXpzODk2MDl4aDJ6bXF6MXVoNmtidCJ9.u82iITmLFSra5PrmIvEPeQ';

export type LocationSuggestion = NonNullable<Vehicle['location']> & { countryCode?: string };

const getSuggestions = async (
  provided: string,
  signal: AbortSignal,
): Promise<LocationSuggestion[] | 'aborted' | 'no suggestion'> => {
  if (!provided) {
    return 'no suggestion';
  }

  try {
    const { features } = await (
      await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          provided,
        )}.json?access_token=${MAPBOX_ACCESS_TOKEN}`,
        { signal },
      )
    ).json();

    return features.length > 0
      ? features
          .filter((feature: any) => feature.place_name && feature.center?.length === 2)
          .map((feature: any) => ({
            name: feature.place_name,
            latitude: feature.center[1],
            longitude: feature.center[0],
            countryCode: feature.context?.find((ctx: any) => ctx.id?.startsWith('country'))?.short_code,
          }))
      : 'no suggestion';
  } catch (e) {
    return 'aborted';
  }
};

export function useMapbox() {
  const [suggestions, setSuggestions] = useState([] as LocationSuggestion[]);
  const controller = useRef<AbortController>();

  const setProvided = useCallback((provided: string) => {
    if (controller.current) {
      controller.current.abort();
    }
    controller.current = new AbortController();

    const timer = setTimeout(async () => {
      const res = await getSuggestions(provided, controller.current!.signal);
      if (res === 'aborted') {
        return;
      }

      if (res === 'no suggestion') {
        setSuggestions([]);
      } else {
        setSuggestions(res);
      }
      controller.current = undefined;
    }, 500);
    controller.current.signal.addEventListener('abort', () => clearTimeout(timer));
  }, []);

  useEffect(
    () => () => {
      if (controller.current) {
        controller.current.abort();
      }
    },
    [],
  );

  return {
    suggestions,
    setProvided,
  };
}
