import { useState, useEffect, useCallback } from 'react';
import { useAuth } from './auth';
import { doFetch } from './common';
import { useFleets } from './fleet';
import { FaqGroup, Faq } from '@solar-data/schemas/lib/owned-by/solar';

interface FaqProps {
  isLoading: boolean;
  faqGroups?: FaqGroup[];
  error?: string;
  addFaq: (faqGroupId: number, faq: Partial<Faq>) => Promise<void>;
  updateFaq: (faqGroupId: number, faqId: number, faq: Partial<Faq>) => Promise<void>;
  deleteFaq: (faqGroupId: number, faqId: number) => Promise<void>;
  addFaqGroup: (faqGroup: Partial<FaqGroup>) => Promise<void>;
  updateFaqGroup: (faqGroupId: number, faqGroup: Partial<FaqGroup>) => Promise<void>;
}

export function useFaqs(): FaqProps {
  const [faqGroups, setFaqGroups] = useState(undefined as FaqGroup[] | undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined as string | undefined);

  const { logout, getHeaders } = useAuth();
  const { currentFleet } = useFleets();

  const reload = useCallback(async () => {
    await doFetch<FaqGroup[]>(
      `api/fleets/${currentFleet}/faqs`,
      { headers: await getHeaders() },
      setIsLoading,
      setError,
      logout,
      setFaqGroups,
    );
  }, [getHeaders, logout, currentFleet]);

  const addFaq = useCallback(
    async (faqGroupId: number, faq: Partial<Faq>) => {
      await doFetch(
        `api/fleets/${currentFleet}/faqs/groups/${faqGroupId}/questions`,
        {
          method: 'POST',
          headers: { ...(await getHeaders()), 'Content-Type': 'application/json' },
          body: JSON.stringify(faq),
        },
        setIsLoading,
        setError,
        logout,
      );
      await reload();
    },
    [getHeaders, logout, currentFleet, reload],
  );

  const updateFaq = useCallback(
    async (faqGroupId: number, faqId: number, faq: Partial<Faq>) => {
      await doFetch(
        `api/fleets/${currentFleet}/faqs/groups/${faqGroupId}/questions/${faqId}`,
        {
          method: 'PUT',
          headers: { ...(await getHeaders()), 'Content-Type': 'application/json' },
          body: JSON.stringify(faq),
        },
        setIsLoading,
        setError,
        logout,
      );
      await reload();
    },
    [getHeaders, logout, currentFleet, reload],
  );

  const deleteFaq = useCallback(
    async (faqGroupId: number, faqId: number) => {
      await doFetch(
        `api/fleets/${currentFleet}/faqs/groups/${faqGroupId}/questions/${faqId}`,
        {
          method: 'DELETE',
          headers: await getHeaders(),
        },
        setIsLoading,
        setError,
        logout,
      );
      await reload();
    },
    [getHeaders, logout, currentFleet, reload],
  );

  const addFaqGroup = useCallback(
    async (faqGroup: Partial<FaqGroup>) => {
      await doFetch(
        `api/fleets/${currentFleet}/faqs/groups`,
        {
          method: 'POST',
          headers: { ...(await getHeaders()), 'Content-Type': 'application/json' },
          body: JSON.stringify(faqGroup),
        },
        setIsLoading,
        setError,
        logout,
      );
      await reload();
    },
    [getHeaders, logout, currentFleet, reload],
  );

  const updateFaqGroup = useCallback(
    async (faqGroupId: number, faqGroup: Partial<FaqGroup>) => {
      await doFetch(
        `api/fleets/${currentFleet}/faqs/groups/${faqGroupId}`,
        {
          method: 'PUT',
          headers: { ...(await getHeaders()), 'Content-Type': 'application/json' },
          body: JSON.stringify(faqGroup),
        },
        setIsLoading,
        setError,
        logout,
      );
      await reload();
    },
    [getHeaders, logout, currentFleet, reload],
  );

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    isLoading,
    faqGroups,
    error,
    addFaq,
    updateFaq,
    deleteFaq,
    addFaqGroup,
    updateFaqGroup,
  };
}
