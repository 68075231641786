import { useState, useCallback } from 'react';
import { useAuth } from './auth';
import { VehicleStatus } from '@solar-data/schemas/lib/owned-by/solar';

function queryRange(start: moment.Moment | undefined, end: moment.Moment | undefined): string {
  let qs = [];
  if (start !== undefined) {
    qs.push(`start=${start.format('YYYY-MM-DD')}`);
  }
  if (end !== undefined) {
    qs.push(`end=${end.format('YYYY-MM-DD')}`);
  }
  return qs.length > 0 ? `?${qs.join('&')}` : '';
}

export function useVehicleStatus() {
  const [queryResult, setQueryResult] = useState(undefined as VehicleStatus[] | undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined as string | undefined);

  const { logout, getHeaders } = useAuth();

  const query = useCallback(
    async (fleetSlug: string, start: moment.Moment | undefined, end: moment.Moment | undefined) => {
      try {
        setQueryResult(undefined);
        setIsLoading(true);
        const resp = await fetch(`/api/fleets/${fleetSlug}/vehicleStatus${queryRange(start, end)}`, {
          headers: await getHeaders(),
        });

        if (resp.ok) {
          setQueryResult(await await resp.json());
        } else if (resp.status === 401) {
          logout();
          window.location.href = '#/login';
        } else {
          throw await resp.text();
        }
      } catch (e) {
        setError(`${e}`);
      } finally {
        setIsLoading(false);
      }
    },
    [getHeaders, logout],
  );

  return { query, queryResult, isLoading, error };
}
