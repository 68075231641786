import React, { useState, useCallback, useEffect } from 'react';
import { useModal } from '../components/modal';
import { useFleets, useVehicles } from '../fetchers/fleet';
import I18n from '../i18n';
import { UserFleet, Vehicle } from '@solar-data/schemas/lib/owned-by/solar';
import moment from 'moment-timezone';
import { useMapbox } from '../fetchers/mapbox';
import Tabs from '../components/tabs';
import { isGranted, IsGranted } from '../privateRoute';
import { ROLE } from '../constants/roles';

function EditVehicleDialog({
  vehicle,
  close,
  fleets,
  currentFleet,
}: {
  fleets: UserFleet[];
  currentFleet: string | undefined;
  vehicle?: Vehicle;
  close: (u: Partial<Vehicle> | undefined | 'delete') => void;
}) {
  const { suggestions, setProvided } = useMapbox();
  const [name, setName] = useState(vehicle?.name ?? '');
  const [deviceIds, setDeviceIds] = useState(vehicle?.deviceIds.join(', ') ?? '');
  const [installedCapacitykW, setInstalledCapacitykW] = useState(vehicle?.installedCapacitykW?.toFixed(3) ?? '0');
  const [firstDay, setFirstDay] = useState(vehicle?.firstDay ?? '');
  const [lastDay, setLastDay] = useState(vehicle?.lastDay ?? '');
  const [location, setLocation] = useState(vehicle?.location);
  const [locationSearch, setLocationSearch] = useState(vehicle?.location?.name || '');
  const [timeZone, setTimeZone] = useState(vehicle?.timeZone ?? 'Europe/Berlin');
  const [additional, setAdditional] = useState(vehicle?.additional ?? {});
  const [installMetaDataType, setInstallMetaDataType] = useState(vehicle?.installMetaData.type ?? 'lv');
  const [installMetaDataMCUVersion, setInstallMetaDataMCUVersion] = useState(vehicle?.installMetaData.mcuVersion ?? '');
  const [installMetaDataState, setInstallMetaDataState] = useState(vehicle?.installMetaData.state ?? 'unknown');
  const ADDITIONAL_FIELDS = ['vehicleType', 'model', 'numberPlate', 'operationMode', 'route'];

  useEffect(() => {
    const match = suggestions.find((s) => s.name === locationSearch);
    if (match) {
      setLocation(match);
      if (match.countryCode && moment.tz.zonesForCountry(match.countryCode)?.length > 0) {
        setTimeZone(moment.tz.zonesForCountry(match.countryCode)[0]);
      }
    } else {
      setProvided(locationSearch);
    }
  }, [locationSearch, setProvided, suggestions]);
  const canEditSpecificAttributes = isGranted([ROLE.CAN_EDIT_SPECIFIC_ATTRIBUTES_VEHICLES], fleets, currentFleet);
  const canEditAllAttributes = isGranted([ROLE.CAN_EDIT_ALL_VEHICLE_ATTRIBUTES], fleets, currentFleet);
  return (
    <div>
      <h2>{I18n.t('solar.fleet.vehicles.edit')}</h2>
      <Tabs
        tabs={[
          {
            name: 'Basic data',
            content: (
              <>
                <label>{I18n.t('solar.fleet.vehicles.name')}</label>
                <input
                  placeholder={I18n.t('solar.fleet.vehicles.name')}
                  value={name || ''}
                  disabled={!canEditAllAttributes && !canEditSpecificAttributes}
                  onChange={(e) => setName(e.target.value)}
                />
                <label>{I18n.t('solar.fleet.vehicles.deviceIds')}</label>
                <input
                  placeholder={I18n.t('solar.fleet.vehicles.deviceIdsExample')}
                  value={deviceIds || ''}
                  disabled={!canEditAllAttributes}
                  onChange={(e) => setDeviceIds(e.target.value)}
                  required
                />
                <label>{I18n.t('solar.fleet.vehicles.installedCapacitykWp')}</label>
                <input
                  placeholder={I18n.t('solar.fleet.vehicles.installedCapacitykWp')}
                  value={installedCapacitykW || ''}
                  disabled={!canEditAllAttributes}
                  onChange={(e) => setInstalledCapacitykW(e.target.value)}
                  required
                />
                <label>{I18n.t('solar.fleet.vehicles.validFrom')}</label>
                <input
                  placeholder="YYYY-MM-DD"
                  value={firstDay || ''}
                  disabled={!canEditAllAttributes}
                  onChange={(e) => setFirstDay(e.target.value)}
                  required
                />
                <label>{I18n.t('solar.fleet.vehicles.validUntil')}</label>
                <input
                  placeholder="YYYY-MM-DD"
                  value={lastDay || ''}
                  disabled={!canEditAllAttributes}
                  onChange={(e) => setLastDay(e.target.value)}
                  required
                />
                <label>{I18n.t('solar.fleet.vehicles.location')}</label>
                <input
                  placeholder={I18n.t('solar.fleet.vehicles.location')}
                  value={locationSearch}
                  disabled={!canEditAllAttributes && !canEditSpecificAttributes}
                  onChange={(e) => setLocationSearch(e.target.value)}
                  list="locations"
                />
                <datalist id="locations">
                  {suggestions.map((suggestion) => (
                    <option key={suggestion.name} value={suggestion.name} />
                  ))}
                </datalist>
                <label>{I18n.t('solar.fleet.vehicles.timeZone')}</label>
                <select
                  onChange={(e) => setTimeZone(e.target.value)}
                  value={timeZone}
                  disabled={!canEditAllAttributes && !canEditAllAttributes}
                >
                  {moment.tz.names().map((tz) => (
                    <option key={tz} value={tz}>
                      {tz}
                    </option>
                  ))}
                </select>
              </>
            ),
          },
          {
            name: 'Additional data',
            content: ADDITIONAL_FIELDS.map((field) => (
              <React.Fragment key={field}>
                <label>{I18n.t(`solar.fleet.vehicles.${field}`)}</label>
                <input
                  placeholder=""
                  value={additional[field] || ''}
                  disabled={!canEditAllAttributes && !canEditSpecificAttributes}
                  onChange={(e) => setAdditional({ ...additional, [field]: e.target.value })}
                />
              </React.Fragment>
            )),
          },
          {
            name: 'Install meta data',
            content: (
              <>
                <React.Fragment key="installMetaDataType">
                  <label>{I18n.t(`solar.fleet.vehicles.installMetaDataType`)}</label>
                  <select
                    value={installMetaDataType ?? ''}
                    disabled={!canEditAllAttributes && !canEditSpecificAttributes}
                    onChange={(e) => setInstallMetaDataType(e.target.value === 'hv' ? 'hv' : 'lv')}
                  >
                    {['lv', 'hv'].map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </React.Fragment>
                <React.Fragment key="installMetaDataMCUVersion">
                  <label>{I18n.t(`solar.fleet.vehicles.installMetaDataMCUVersion`)}</label>
                  <input
                    placeholder=""
                    value={installMetaDataMCUVersion ?? ''}
                    disabled={!canEditAllAttributes && !canEditSpecificAttributes}
                    onChange={(e) => setInstallMetaDataMCUVersion(e.target.value)}
                  />
                </React.Fragment>
                <React.Fragment key="installMetaDataState">
                  <label>{I18n.t(`solar.fleet.vehicles.installMetaDataState`)}</label>
                  <select
                    value={installMetaDataState ?? ''}
                    disabled={!canEditAllAttributes && !canEditSpecificAttributes}
                    onChange={(e) => setInstallMetaDataState(e.target.value as any)}
                  >
                    {['installed', 'planned', 'disconnected', 'errored', 'unknown'].map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </React.Fragment>
              </>
            ),
          },
        ]}
      />
      <div className="buttons">
        <button
          onClick={() =>
            close({
              name,
              deviceIds: deviceIds
                .split(/,|;/)
                .map((d) => d.trim())
                .filter((d) => !!d),
              installedCapacitykW: Number.parseFloat(installedCapacitykW),
              firstDay: firstDay || undefined,
              lastDay: lastDay || undefined,
              location,
              additional,
              timeZone,
              installMetaData: {
                type: installMetaDataType || 'lv',
                mcuVersion: installMetaDataMCUVersion || '',
                state: installMetaDataState || 'unknown',
              },
            })
          }
          disabled={
            !name ||
            !firstDay.match(/^(\d\d\d\d-\d\d-\d\d)?$/) ||
            !lastDay.match(/^(\d\d\d\d-\d\d-\d\d)?$/) ||
            (locationSearch.trim() !== '' && locationSearch !== location?.name)
          }
        >
          {I18n.t('solar.ok')}
        </button>
        <button onClick={() => close(undefined)}>{I18n.t('solar.cancel')}</button>
        {vehicle && isGranted([ROLE.CAN_DELETE_USERS], fleets, currentFleet) ? (
          <button onClick={() => close('delete')}>{I18n.t('solar.fleet.deleteButton')}</button>
        ) : null}
      </div>
    </div>
  );
}

export default function Vehicles() {
  const { vehicles, createVehicle, updateVehicle, deleteVehicle } = useVehicles();
  const { fleets, currentFleet } = useFleets();
  const modal = useModal();

  const addOrEdit = useCallback(
    async (vehicle?: Vehicle) => {
      const result = await modal<Partial<Vehicle> | undefined | 'delete'>((close) => (
        <EditVehicleDialog vehicle={vehicle} close={close} fleets={fleets} currentFleet={currentFleet} />
      ));
      if (result === 'delete' && vehicle) {
        await deleteVehicle(vehicle.id);
      } else if (result !== undefined && result !== 'delete' && vehicle) {
        await updateVehicle(vehicle.id, result);
      } else if (result !== undefined && result !== 'delete' && !vehicle) {
        await createVehicle(result);
      }
    },
    [modal, updateVehicle, deleteVehicle, createVehicle, fleets, currentFleet],
  );

  return (
    <div className="cards">
      <IsGranted roles={[ROLE.CAN_VIEW_VEHICLES]}>
        <div className="card full-width">
          <h2>{I18n.t('solar.fleet.vehicles.title')}</h2>
          <table>
            <thead>
              <tr>
                <th>{I18n.t('solar.fleet.vehicles.name')}</th>
                <th className="no-mobile">{I18n.t('solar.fleet.vehicles.deviceIds')}</th>
                <th className="no-mobile">{I18n.t('solar.fleet.vehicles.installedCapacity')}</th>
                <th className="no-mobile">{I18n.t('solar.fleet.vehicles.validity')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {vehicles.map((v) => (
                <tr key={v.id}>
                  <td>{v.name}</td>
                  <td className="no-mobile">{v.deviceIds && v.deviceIds.length > 0 ? v.deviceIds.join(', ') : '-'}</td>
                  <td className="no-mobile">{v.installedCapacitykW} kWp</td>
                  <td className="no-mobile">
                    {v.firstDay
                      ? v.lastDay
                        ? `${v.firstDay} - ${v.lastDay}`
                        : I18n.t('solar.fleet.vehicles.validFromDay', { from: v.firstDay })
                      : v.lastDay
                      ? I18n.t('solar.fleet.vehicles.validUntilDay', { until: v.lastDay })
                      : '-'}
                  </td>
                  <td>
                    <IsGranted roles={[ROLE.CAN_EDIT_VEHICLES]}>
                      <button className="small" onClick={() => addOrEdit(v)}>
                        {I18n.t('solar.fleet.editButton')}
                      </button>
                    </IsGranted>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>
            <IsGranted roles={[ROLE.CAN_ADD_VEHICLES]}>
              <button onClick={() => addOrEdit()}>{I18n.t('solar.fleet.addButton')}</button>
            </IsGranted>
          </p>
        </div>
      </IsGranted>
    </div>
  );
}
