import React, { useState } from 'react';
import './tabs.scss';

export default function Tabs({ tabs }: { tabs: { name: string; content: React.ReactNode }[] }) {
  const [active, setActive] = useState(0);
  return (
    <>
      <div className="tabs">
        {tabs.map((tab, idx) => (
          <div key={idx} className={`tab ${idx === active ? 'active' : ''}`} onClick={() => setActive(idx)}>
            {tab.name}
          </div>
        ))}
      </div>
      {tabs[active].content}
    </>
  );
}
