import React from 'react';
import { useDocuments } from '../fetchers/documents';
import { useFleets } from '../fetchers/fleet';
import I18n from '../i18n';
import './documents.scss';
import { DocumentType } from '@solar-data/schemas/lib/owned-by/solar';

export default function Documents({ documentType }: { documentType: DocumentType }) {
  const { currentFleet } = useFleets();
  const { documents } = useDocuments(documentType);

  return (
    <>
      <a className="back" href={`#/${currentFleet}`}>
        {I18n.t('solar.documents.backToDashboard')}
      </a>
      <div className="documents">
        <h1>{I18n.t(`solar.documents.${documentType}.title`)}</h1>
        {documents ? (
          documents.length === 0 ? (
            <p>{I18n.t(`solar.documents.${documentType}.empty`)}</p>
          ) : (
            <table>
              <tbody>
                {documents.map((doc) => (
                  <tr key={doc.name}>
                    <td>
                      <a href={doc.url} target="_blank" rel="noreferrer">
                        <img src="document.png" alt="" /> {doc.name}
                      </a>
                    </td>
                    <td className="no-mobile">
                      <a href={doc.url} target="_blank" rel="noreferrer">
                        {doc.date?.substring(0, 10)}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        ) : (
          <div className="spinner" />
        )}
      </div>
    </>
  );
}
