import { useState, useEffect, useCallback } from 'react';
import { useAuth } from './auth';
import { doFetch } from './common';
import { useOperatingModes } from './fleet';
import { OperatingMode, VehicleOperatingMode } from '@solar-data/schemas/lib/owned-by/solar';
import moment from 'moment';
export interface UiVehicleOperatingMode extends VehicleOperatingMode {
  mode?: OperatingMode;
}

interface VehicleOperatingModeProps {
  isLoading: boolean;
  vehicleOperatingModes?: UiVehicleOperatingMode[];
  error?: string;

  setMode: (day: string, id: number, reason: string) => void;
}

export function useVehicleOperatingModes(fleetSlug: string, vehicleId: number): VehicleOperatingModeProps {
  const [vehicleOperatingModes, setVehicleOperatingModes] = useState(undefined as UiVehicleOperatingMode[] | undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined as string | undefined);
  const { operatingModes } = useOperatingModes();

  const { logout, getHeaders } = useAuth();

  const reload = useCallback(
    async () =>
      doFetch<VehicleOperatingMode[]>(
        `api/fleets/${fleetSlug}/vehicles/${vehicleId}/operatingModes`,
        { headers: await getHeaders() },
        setIsLoading,
        setError,
        logout,
        (voms) =>
          setVehicleOperatingModes(
            voms?.map((vom) => ({ ...vom, mode: operatingModes.find((om) => om.id === vom.id) })),
          ),
      ),
    [getHeaders, logout, fleetSlug, vehicleId],
  );

  useEffect(() => {
    reload();
  }, [reload]);

  const setMode = useCallback(
    async (day: string, id: number, reason: string) => {
      const dayDate = moment(day).format('YYYY-MM-DD');
      await doFetch(
        `api/fleets/${fleetSlug}/vehicles/${vehicleId}/operatingModes/${dayDate}`,
        {
          method: 'PUT',
          headers: {
            ...(await getHeaders()),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id, reason }),
        },
        setIsLoading,
        setError,
        logout,
      );
      await reload();
    },
    [getHeaders, logout, reload, fleetSlug, vehicleId],
  );

  return { setMode, vehicleOperatingModes, isLoading, error };
}
