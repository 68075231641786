import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import I18n from '../i18n';

const OPENWEATHERMAP_APPID = '4d1e7b2ad09686a6c9ae8ab654466d0d';

export interface WeatherData {
  sunrise: moment.Moment;
  sunset: moment.Moment;
  cloudsPercent: number;
  temperatureC: number;
  description: string;
  icon: string;
}

export interface SpaceTimeCoordinates {
  latitude: number;
  longitude: number;
  day: string;
  timeZone: string;
}

export function useWeather(): {
  weather: WeatherData | undefined;
  setLocation: (latitude: number, longitude: number, day: string, timeZone: string) => void;
  clearWeather: () => void;
} {
  const [location, setLocation] = useState(undefined as SpaceTimeCoordinates | undefined);
  const [weather, setWeather] = useState(undefined as WeatherData | undefined);
  const controller = useRef<AbortController>();

  useEffect(() => {
    (async () => {
      if (controller.current) {
        controller.current.abort();
      }
      controller.current = new AbortController();

      setWeather(undefined);
      if (!location) {
        return;
      }

      try {
        const rawWeather = await (
          await fetch(
            `https://api.openweathermap.org/data/3.0/onecall/timemachine?${[
              `lat=${location.latitude}`,
              `lon=${location.longitude}`,
              `appid=${OPENWEATHERMAP_APPID}`,
              `dt=${moment.tz(location.day, location.timeZone).set('hour', 12).unix()}`,
              `units=metric`,
              `lang=${I18n.currentLocale()}`,
            ].join('&')}`,
            { signal: controller.current.signal },
          )
        ).json();

        if (!rawWeather.data[0]) {
          return;
        }

        setWeather({
          sunrise: moment.unix(rawWeather.data[0].sunrise).tz(location.timeZone),
          sunset: moment.unix(rawWeather.data[0].sunset).tz(location.timeZone),
          cloudsPercent: rawWeather.data[0].clouds,
          temperatureC: rawWeather.data[0].temp?.toFixed(1),
          description: rawWeather.data[0].weather[0]?.description ?? '-',
          icon: `https://openweathermap.org/img/wn/${rawWeather.data[0].weather[0]?.icon}@2x.png`,
        });
      } catch (e) {
        console.error('Error fetching weather:', e);
      } finally {
        controller.current = undefined;
      }
    })();
  }, [location]);

  return {
    weather,
    setLocation: (latitude: number, longitude: number, day: string, timeZone: string) =>
      setLocation({ latitude, longitude, day, timeZone }),
    clearWeather: () => setLocation(undefined),
  };
}
