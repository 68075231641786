import React, { useState, useCallback } from 'react';
import I18n from '../i18n';
import './faqs.scss';
import marked from 'marked';
import { useFleets } from '../fetchers/fleet';
import { useModal } from '../components/modal';
import { useFaqs } from '../fetchers/faqs';
import { Faq, FaqGroup, LocalString, UserFleet } from '@solar-data/schemas/lib/owned-by/solar';
import { isGranted, IsGranted } from '../privateRoute';
import { ROLE } from '../constants/roles';

function local(str: LocalString): string {
  return [str[I18n.currentLocale()], str.en, str.de].find((s) => s.trim() !== '') ?? '?';
}

function EditQuestionDialog({
  faq,
  close,
  fleets,
  currentFleet,
}: {
  fleets: UserFleet[];
  currentFleet: string | undefined;
  faq?: Faq;
  close: (u: Partial<Faq> | undefined | 'delete') => void;
}) {
  const [newFaq, setNewFaq] = useState({
    question: { en: faq?.question.en || '', de: faq?.question.de || '' },
    answer: { en: faq?.answer.en || '', de: faq?.answer.de || '' },
  } as any);

  return (
    <div>
      <h2>{I18n.t('solar.faqs.edit.editQuestion')}</h2>
      {['question', 'answer'].map((field) =>
        ['en', 'de'].map((lang) => (
          <React.Fragment key={`${field}${lang}`}>
            <label>{I18n.t(`solar.faqs.edit.${field}`, { lang })}</label>
            <textarea
              value={newFaq[field][lang] || ''}
              onChange={(e) =>
                setNewFaq({
                  ...newFaq,
                  [field]: { ...newFaq[field], [lang]: e.target.value },
                })
              }
              required
            />
          </React.Fragment>
        )),
      )}
      <div className="buttons">
        <button onClick={() => close(newFaq)}>{I18n.t('solar.ok')}</button>
        <button onClick={() => close(undefined)}>{I18n.t('solar.cancel')}</button>
        {faq && isGranted([ROLE.CAN_DELETE_FAQS], fleets, currentFleet) ? (
          <button onClick={() => close('delete')}>{I18n.t('solar.faqs.edit.deleteButton')}</button>
        ) : undefined}
      </div>
    </div>
  );
}

function EditGroupDialog({ faqGroup, close }: { faqGroup?: FaqGroup; close: (u: Partial<Faq> | undefined) => void }) {
  const [newFaqGroup, setNewFaqGroup] = useState({
    title: {
      de: faqGroup?.title.de || '',
      en: faqGroup?.title.en || '',
    },
  } as any);

  return (
    <div>
      <h2>{I18n.t('solar.faqs.edit.editGroup')}</h2>
      <label>{I18n.t(`solar.faqs.edit.groupTitle`, { lang: 'en' })}</label>
      <textarea
        value={newFaqGroup.title.en || ''}
        onChange={(e) => setNewFaqGroup({ ...newFaqGroup, title: { ...newFaqGroup.title, en: e.target.value } })}
        required
      />
      <label>{I18n.t(`solar.faqs.edit.groupTitle`, { lang: 'de' })}</label>
      <textarea
        value={newFaqGroup.title.de || ''}
        onChange={(e) => setNewFaqGroup({ ...newFaqGroup, title: { ...newFaqGroup.title, de: e.target.value } })}
        required
      />
      <div className="buttons">
        <button onClick={() => close(newFaqGroup)}>{I18n.t('solar.ok')}</button>
        <button onClick={() => close(undefined)}>{I18n.t('solar.cancel')}</button>
      </div>
    </div>
  );
}

function Question({ question, editQuestion }: { question: Faq; editQuestion: (faq: Faq) => Promise<void> }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <dt className={isOpen ? 'open' : undefined} onClick={() => setOpen((o) => !o)}>
        <IsGranted roles={[ROLE.CAN_EDIT_FAQ_QUESTIONS]}>
          <button onClick={(e) => editQuestion(question)} className="edit editQuestion">
            🖊
          </button>
        </IsGranted>
        {local(question.question)}
      </dt>
      {isOpen ? <dd dangerouslySetInnerHTML={{ __html: marked(local(question.answer)) }} /> : null}
    </>
  );
}

export default function Faqs() {
  const { faqGroups, updateFaq, addFaq, deleteFaq, addFaqGroup, updateFaqGroup } = useFaqs();
  const { fleets, currentFleet } = useFleets();
  const [showEmptyGroups, setShowEmptyGroups] = useState(true);
  const modal = useModal();

  //useEffect(() => setShowEmptyGroups(!faqGroups?.find((g) => g.questions.length === 0)), [faqGroups]);

  const editQuestion = useCallback(
    async (faqGroup: FaqGroup, faq?: Faq) => {
      const newFaq = await modal<Partial<Faq> | undefined | 'delete'>((close) => (
        <EditQuestionDialog close={close} faq={faq} fleets={fleets} currentFleet={currentFleet} />
      ));
      if (newFaq) {
        if (newFaq === 'delete' && faq) {
          await deleteFaq(faqGroup.id, faq.id);
        } else if (newFaq !== 'delete' && faq) {
          await updateFaq(faqGroup.id, faq.id, newFaq);
        } else if (newFaq !== 'delete') {
          await addFaq(faqGroup.id, newFaq);
        }
      }
    },
    [modal, updateFaq, addFaq, deleteFaq, currentFleet, fleets],
  );

  const editGroup = useCallback(
    async (faqGroup?: FaqGroup) => {
      const newFaqGroup = await modal<Partial<FaqGroup> | undefined>((close) => (
        <EditGroupDialog close={close} faqGroup={faqGroup} />
      ));
      if (newFaqGroup) {
        if (faqGroup) {
          await updateFaqGroup(faqGroup.id, newFaqGroup);
        } else {
          await addFaqGroup(newFaqGroup);
        }
      }
    },
    [modal, addFaqGroup, updateFaqGroup],
  );

  return (
    <IsGranted roles={[ROLE.CAN_VIEW_FAQS]}>
      <div className="faqs">
        <div className="header">
          <h1>{I18n.t(`solar.faqs.title`)}</h1>
          <p className="intro">{I18n.t(`solar.faqs.intro`)}</p>
        </div>
        {(faqGroups || [])
          .filter((faqGroup) => showEmptyGroups || faqGroup.questions.length > 0)
          .map((faqGroup) => (
            <div key={faqGroup.id} className="faqGroup">
              <h2>
                {local(faqGroup.title)}{' '}
                <IsGranted roles={[ROLE.CAN_EDIT_FAQ_GROUPS]}>
                  <button onClick={(e) => editGroup(faqGroup)} className="edit editGroup">
                    🖊
                  </button>
                </IsGranted>
                <IsGranted roles={[ROLE.CAN_ADD_FAQ_QUESTIONS]}>
                  <button className="edit addQuestion" onClick={() => editQuestion(faqGroup)}>
                    +
                  </button>
                </IsGranted>
              </h2>
              <dl>
                {faqGroup.questions.map((faq) => (
                  <Question key={faq.id} question={faq} editQuestion={(faq: Faq) => editQuestion(faqGroup, faq)} />
                ))}
              </dl>
            </div>
          ))}
        <IsGranted roles={[ROLE.CAN_ADD_FAQ_GROUP]}>
          <div className="addGroup">
            <button
              className="edit addGroup"
              onClick={() => (!showEmptyGroups ? setShowEmptyGroups(true) : editGroup())}
            >
              +
            </button>
          </div>
        </IsGranted>
      </div>
    </IsGranted>
  );
}
