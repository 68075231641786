import React, { useCallback, useState } from 'react';
import { useModal } from '../components/modal';
import { useFleets } from '../fetchers/fleet';
import { Fleet, UserFleet } from '@solar-data/schemas/lib/owned-by/solar';
import I18n from '../i18n';
import { IsGranted, isGranted } from '../privateRoute';
import { ROLE } from '../constants/roles';

function EditFleetDialog({
  fleet,
  close,
  userFleets,
  currentFleet,
}: {
  userFleets: UserFleet[];
  currentFleet: string | undefined;
  fleet?: Fleet;
  close: (u: Partial<Fleet> | undefined | 'delete') => void;
}) {
  const [name, setName] = useState(fleet?.name ?? '');
  const [slug, setSlug] = useState(fleet?.slug ?? '');
  const [fleetType, setFleetType] = useState(fleet?.fleetType || 'bus-kit');

  return (
    <div>
      <h2>{I18n.t('solar.fleet.fleets.edit')}</h2>
      <label>{I18n.t('solar.fleet.fleets.slug')}</label>
      <input
        placeholder={I18n.t('solar.fleet.fleets.slug')}
        value={slug}
        onChange={(e) => setSlug(e.target.value.toLocaleLowerCase().replace(/[^a-z0-9]/g, ''))}
        required
        disabled={!!fleet}
      />
      <label>{I18n.t('solar.fleet.fleets.name')}</label>
      <input
        placeholder={I18n.t('solar.fleet.fleets.name')}
        value={name}
        onChange={(e) => setName(e.target.value.trim())}
        required
      />
      <label>{I18n.t('solar.fleet.fleets.fleetType')}</label>
      <select onChange={(e) => setFleetType(e.target.value as Fleet['fleetType'])} value={fleetType}>
        <option value={'bus-kit'}>{I18n.t('solar.fleet.fleets.fleetTypeBusKit')}</option>
        <option value={'svc3'}>{I18n.t('solar.fleet.fleets.fleetTypeSvc3')}</option>
        <option value={'campingworld'}>{I18n.t('solar.fleet.fleets.fleetTypeCampingworld')}</option>
      </select>
      <div className="buttons">
        <button onClick={() => close({ slug, name, fleetType })}>{I18n.t('solar.ok')}</button>
        <button onClick={() => close(undefined)}>{I18n.t('solar.cancel')}</button>
        {fleet && isGranted([ROLE.CAN_DELETE_FLEETS], userFleets, currentFleet) ? (
          <button onClick={() => close('delete')}>{I18n.t('solar.fleet.deleteButton')}</button>
        ) : undefined}
      </div>
    </div>
  );
}

export default function Fleets() {
  const { fleets, createFleet, updateFleet, deleteFleet, currentFleet } = useFleets();
  const modal = useModal();

  const add = useCallback(async () => {
    const newFleet = await modal<Partial<Fleet> | undefined | 'delete'>((close) => (
      <EditFleetDialog close={close} userFleets={fleets} currentFleet={currentFleet} />
    ));
    if (newFleet && newFleet !== 'delete') {
      await createFleet(newFleet);
    }
  }, [modal, createFleet, fleets, currentFleet]);

  const edit = useCallback(
    async (fleet: Fleet) => {
      const result = await modal<Partial<Fleet> | undefined | 'delete'>((close) => (
        <EditFleetDialog fleet={fleet} close={close} userFleets={fleets} currentFleet={currentFleet} />
      ));
      if (result === 'delete') {
        await deleteFleet(fleet.slug);
      } else if (result !== undefined) {
        await updateFleet(fleet.slug, result);
      }
    },
    [modal, updateFleet, deleteFleet, fleets, currentFleet],
  );

  return (
    <div className="cards">
      <div className="card full-width">
        <h2>{I18n.t('solar.fleet.fleets.title')}</h2>
        <table>
          <thead>
            <tr>
              <th>{I18n.t('solar.fleet.fleets.slug')}</th>
              <th>{I18n.t('solar.fleet.fleets.name')}</th>
              <th>{I18n.t('solar.fleet.fleets.fleetType')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {fleets.map((f) => (
              <tr key={f.slug}>
                <td>{f.slug}</td>
                <td>{f.name}</td>
                <td>
                  {I18n.t(
                    `solar.fleet.fleets.fleetType${(f.fleetType || 'bus-kit').replaceAll(/(?:^|-)(.)/g, (_, x) =>
                      x.toUpperCase(),
                    )}`,
                  )}
                </td>
                <IsGranted roles={[ROLE.CAN_EDIT_FLEETS]}>
                  <td>
                    <button className="small" onClick={() => edit(f)}>
                      {I18n.t('solar.fleet.editButton')}
                    </button>
                  </td>
                </IsGranted>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          <IsGranted roles={[ROLE.CAN_ADD_FLEETS]}>
            <button onClick={add}>{I18n.t('solar.fleet.addButton')}</button>
          </IsGranted>
        </p>
      </div>
    </div>
  );
}
