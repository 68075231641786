export async function doFetch<T>(
  url: string,
  request: RequestInit,
  setIsLoading: (isLoading: boolean) => void,
  setError: (error: string) => void,
  logout: () => void,
  consume: (data: T | undefined) => void = (data) => {},
) {
  try {
    consume(undefined);
    setIsLoading(true);
    const resp = await fetch(url, request);

    if (resp.ok) {
      consume(await resp.json());
    } else if (resp.status === 401) {
      logout();
      window.location.href = '#/login';
    } else {
      throw await resp.text();
    }
  } catch (e) {
    setError(`${e}`);
  } finally {
    setIsLoading(false);
  }
}
