import { useState, useCallback } from 'react';
import { useAuth } from './auth';
import moment from 'moment';
import { EnergyEarnings } from '@solar-data/schemas/lib/owned-by/solar';

interface EnergyEarningsProps {
  query: (
    fleetSlug: string,
    vehicleId: number | undefined,
    start: moment.Moment | undefined,
    end: moment.Moment | undefined,
    by: 'day' | 'hour',
    includeOperatingModes: number[] | undefined,
  ) => void;
  isLoading: boolean;
  queryResult?: EnergyEarnings;
  error?: string;
}

function queryString(
  start: moment.Moment | undefined,
  end: moment.Moment | undefined,
  by: 'day' | 'hour' | undefined,
  includedOperatingModes: number[] | undefined,
): string {
  let qs = [] as string[];
  if (start !== undefined) {
    qs.push(`start=${start.format('YYYY-MM-DD')}`);
  }
  if (end === undefined) {
    end = moment();
  }
  if (end !== undefined) {
    qs.push(`end=${end.format('YYYY-MM-DD')}`);
  }
  if (by !== undefined) {
    qs.push(`by=${by}`);
  }
  if (includedOperatingModes !== undefined) {
    qs.push(...includedOperatingModes.map((om) => `operatingModes=${om}`));
  }
  return qs.length > 0 ? `?${qs.join('&')}` : '';
}

export function useEnergyEarnings(): EnergyEarningsProps {
  const [queryResult, setQueryResult] = useState(undefined as EnergyEarnings | undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined as string | undefined);

  const { logout, getHeaders } = useAuth();

  const query = useCallback(
    async (
      fleetSlug: string,
      vehicleId: number | undefined,
      start: moment.Moment | undefined,
      end: moment.Moment | undefined,
      by: 'day' | 'hour' = 'day',
      includedOperatingModes: number[] | undefined,
    ) => {
      try {
        setQueryResult(undefined);
        setIsLoading(true);
        const resp = await fetch(
          `/api/fleets/${fleetSlug}${vehicleId ? `/vehicles/${vehicleId}` : ''}/energyEarnings${queryString(
            start,
            end,
            vehicleId ? by : undefined,
            includedOperatingModes,
          )}`,
          {
            headers: await getHeaders(),
          },
        );

        if (resp.ok) {
          const queryResult = await resp.json();
          setQueryResult(queryResult);
        } else if (resp.status === 401) {
          logout();
          window.location.href = '#/login';
        } else {
          throw await resp.text();
        }
      } catch (e) {
        setError(`${e}`);
      } finally {
        setIsLoading(false);
      }
    },
    [getHeaders, logout],
  );

  return { query, queryResult, isLoading, error };
}
